import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export function isMarketplaceDomain(): boolean {
  const domain = environment.host;
  if (domain) {
    return domain.includes('anybuddyapp.com');
  }
  return true;
}

export function isForestHillDomain(): boolean {
  const domain = environment.host;
  if (domain) {
    return domain.includes('forest-hill.fr');
  }
  return false;
}

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  private _isWhiteLabel: boolean;

  constructor() {
    this._isWhiteLabel = !isMarketplaceDomain();
  }

  getisWhiteLabel(): boolean {
    return this._isWhiteLabel;
  }
}
